<template>
  <div class="mainform">
    <div class="mainHeader">
      <span v-if="!id">新建出库单</span>
      <span v-if="id">出库单详情</span>
      <span @click="back" class="back">返回></span>
    </div>
    <div class="form mg-form perch-mg-form">
      <el-form class="content bascform" :class="{disabled:formDisabled}" :disabled="formDisabled" ref="form" :model="form" label-width="120px">
        <div class="col col4" v-if="id">
          <el-form-item label="出库单编号">
            <el-input v-model="dataBox.ruleSupplier" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="供应商">
            <el-select
            filterable
            remote
            reserve-keyword
            value-key="id"
            v-model="dataBox.ruleSupplierNumber"
            @change="disChange"
            placeholder="请选择或输入匹配供应商">
              <el-option
                :key="index"
                v-for="(itme,index) in supplierListData"
                :label="itme.value"
                :value="itme.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item v-if="id" label="出库时间">
            <el-input v-model="dataBox.createDate" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
        <div class="col col4" v-if="!id">
          <el-form-item label="经营范围">
            <el-select v-model="dataBox.businessScope" @change="fwChange">
              <el-option :key="index" v-for="(itme,index) in businessScopeList" :label="itme.companyDesc" :value="itme.businessScope"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col col4" v-if="!id"></div>
        <div class="col col4" v-if="id">
          <el-form-item label="操作人">
            <el-input v-model="dataBox.createUserId" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
        <div class="col col4" v-if="id">
          <el-form-item label="操作类型">
            <el-input v-model="dataBox.ruleType" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
        <div class="col col4" v-if="id">
          <el-form-item label="状态">
            <el-input v-model="dataBox.proofType" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
        <div class="purchClass">
          <el-form-item label="出库物料">
            <div class="clearfix" v-if="!id">
              <div class="btnlaft mg-btnlaft">
                <el-button size="small" @click="showMateriel" type="primary" :disabled=keDisabled plain>添加物料</el-button>
              </div>
              <div class="btnlaft mg-btnlaft">
                <el-upload
                  :disabled=keDisabled
                  ref="upload"
                  name="file"
                  :auto-upload="true"
                  :action= 'postUrl'
                  :show-file-list="false"
                  :on-exceed="handleExceed"
                  :on-change="handleChansge"
                  :on-success="handSuccess"
                  :on-error="handError"
                  :headers="tokenHeader"
                >
                <el-button size="small" :disabled=keDisabled type="primary" plain>上传excel</el-button>
              </el-upload>
              </div>
              <div class="downMb">
                <el-button @click="downCommonTemplate" size="small" type="primary" plain>下载模板</el-button>
              </div>
            </div>
            <div class="excel-tab">
              <el-table size=mini :data="form.bidScoringList" stripe style="width: 100%">
                <el-table-column prop="indexNum" type="index" label="序号" width="50"></el-table-column>
                <el-table-column prop="materialCode" label="物料编号" :show-overflow-tooltip="true" width="180">
                  <template #default="scope" v-if="btnStarts == 1">
                    <el-input @blur="itCodeBlur(scope.row)"
                    @keyup.enter="itCodeEnter(scope.row, $event)" v-model="scope.row.materialCode" :disabled="tableDisabled" placeholder="请输入"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="materialDesc" label="物料描述" :show-overflow-tooltip="true" width=""></el-table-column>
                <!-- <el-table-column prop="materialType" label="物料类别" :show-overflow-tooltip="true" width="90">
                  <template #default="scope">
                    {{scope.row.materialType=="1"?"普通":scope.row.materialType=="2"?"专用":scope.row.materialType=="3"?"AVAP":""}}
                  </template>
                </el-table-column> -->
                <el-table-column prop="units" label="单位" :show-overflow-tooltip="true" width="60"></el-table-column>
                <el-table-column v-if="!id" prop="inventory" label="账面库存" :show-overflow-tooltip="true" width="80"></el-table-column>

                <el-table-column v-if="id" prop="materialNum" label="出库数量" :show-overflow-tooltip="true" width="80"></el-table-column>
                <el-table-column v-if="!id" prop="materialNum" label="出库数量" :show-overflow-tooltip="true" width="110">
                  <template #default="scope">
                    <el-input v-model="scope.row.materialNum" @blur="ckNum(scope.row)" @input="(val)=>{scope.row.materialNum = val.replace(/\D|^[0]+/g,'')}" placeholder="请输入"></el-input>
                  </template>
                </el-table-column>

                <el-table-column prop="storage" label="库位" :show-overflow-tooltip="true" width="100"></el-table-column>

                <!-- <el-table-column prop="ruleStorage" label="库位" :show-overflow-tooltip="true" v-if="!id" width="150">
                  <template  #header>
                    <el-select
                    v-model="form.ruleStorage"
                    filterable
                    remote
                    reserve-keyword
                    value-key="id"
                    placeholder="库位"
                    @change="handleSelect"
                    @focus="clearDataTop"
                    :remote-method="querySearch">
                      <el-option
                        v-for='item in newSelect'
                        :key='item.id'
                        :label='item.lable'
                        :value='item'>
                      </el-option>
                    </el-select>
                  </template>

                  <template #default="scope">
                    <el-select
                    v-model="scope.row.ruleStorage"
                    filterable
                    remote
                    reserve-keyword
                    value-key="id"
                    placeholder="库位"
                    @focus="clearData(scope.row)"
                    :remote-method="querySearch">
                      <el-option
                        v-for='item in newSelect'
                        :key='item.id'
                        :label='item.lable'
                        :value='item'>
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column> -->

                <el-table-column v-if="id" prop="storageType" label="库位类型" :show-overflow-tooltip="true" width="80">
                  <template #default="scope">
                    {{scope.row.storageType=="1"?"外协库":scope.row.storageType=="2"?"返修库":scope.row.storageType=="3"?"工厂库":scope.row.storageType=="4"?"VMI库":""}}
                  </template>
                </el-table-column>
                <el-table-column v-if="!id" label="操作" width="90">
                  <template #default="scope">
                    <div v-if="btnStarts == 1">
                      <template v-if="form.bidScoringList.length === 1">
                        <el-button size="mini" type="primary" @click="addBidTeam(scope.$index)" icon="el-icon-plus" circle></el-button>
                      </template>
                      <template v-else-if="form.bidScoringList.length === scope.$index + 1">
                        <el-button size="mini" type="primary" @click="removeBidTeam(scope.$index)" icon="el-icon-minus" circle></el-button>
                        <el-button size="mini" type="primary" @click="addBidTeam(scope.$index)" icon="el-icon-plus" circle></el-button>
                      </template>
                      <template v-else>
                        <el-button size="mini" type="primary" @click="removeBidTeam(scope.$index)" icon="el-icon-minus" circle></el-button>
                      </template>
                    </div>
                    <div v-else>
                      <el-button size="mini" type="primary" @click="removeBidTeam(scope.$index)" icon="el-icon-minus" circle></el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </div>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="dataBox.ruleComment" placeholder="请填写备注"></el-input>
        </el-form-item>
        <div class="action" v-if="!id">
          <el-button class="submit" type="primary" size="medium" @click="preservation">确认出库</el-button>
        </div>
      </el-form>
      <div v-if="dataBox.proofType === '已出库'">
        <div class="action" v-if="id">
          <el-button class="submit" type="primary" size="medium" @click="deliverGoods">撤销</el-button>
        </div>
      </div>
    </div>
    <dialog-box ref="queryDialog" dialogWidth="80%" :dialogShow="dialogShow" :dataBox="dataBox" @handleClose='handleClose' title="添加物料" componentName="AddOutLibraryBody" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'action', type:'primary', size:'mini'}]" @action="action"></dialog-box>
  </div>
</template>
<script>
import Component from '@/assets/js/components.js'
import { baseURL, request, exportForm } from '@/assets/js/http.js'
import { bidSupplierList } from './js/setting.js'
export default {
  components: Component.components,
  name: 'AddOutLibrary',
  props: [],
  data: function () {
    return {
      newSupplierCode: '',
      newScopeOfBusiness: '',
      tableDisabled: true,
      keDisabled: true,
      formDisabled: false,
      id: this.$route.query.id,
      dialogShow: false,
      businessScopeList: [],
      supplierListData: [],
      newSelect: [],
      dataBox: {},
      btnStarts: 1,
      clrarList: 1,
      hideStarts: 1,
      menudata: this.$store.state.ViewMenuData.buttonData,
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      postUrl: '',
      form: {
        xjDescribe: '',
        checkBusinessScope: '',
        creationDate: '',
        createUsername: '',
        natureBusiness: '',
        proofType: '',
        ruleType: '',
        formData: bidSupplierList,
        bidScoringList: [{}]
      }
    }
  },
  mounted () {
    if (this.id) {
      this.formDisabled = true
    }
    request('/api/sap/businessScope/getBusinessScopeList', 'get').then((response) => {
      this.businessScopeList = response
    })
  },
  onUnmounted () {
  },
  created () {
    this.getIdData()
    this.supplierList()
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  },
  methods: {
    // 根据ID请求数据
    getIdData () {
      const id = this.id
      if (id) {
        request('/api/order/PurchasingSystem/queryEkpo?' + 'id=' + id, 'get').then((res) => {
          if (res.code === '200') {
            this.dataBox = res.data.ruleMaterialProofDto
            this.form.bidScoringList = res.data.ruleMaterialProofDtos

            // 判断入库时间是否超过24小时，超过24小时隐藏撤销按钮
            // var rkTime = this.dataBox.createDate
            // var newEspData = ''
            // const expDate = new Date()
            // const year = expDate.getFullYear()
            // const month = expDate.getMonth() + 1
            // const day = expDate.getDate() + 1
            // const h = new Date().getHours()
            // const m = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
            // const s = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
            // newEspData = year + '-' + month.toString().padStart(2, 0) + '-' + day.toString().padStart(2, 0) + ' ' + h + ':' + m + ':' + s

            // if (rkTime < newEspData) {
            //   this.hideStarts = 2
            // }
          }
        })
      }
    },
    // 表头库存输入下拉框选中值以后，对应整列下拉框赋值
    handleSelect (val) {
      this.form.bidScoringList.forEach(item => {
        item.ruleStorage = val
      })
    },
    // 表格里的选择库位输入后匹配内容
    querySearch (queryString) {
      var str = queryString.toString()
      // 匹配库位
      request('/api/livraison/entrepot/getEntrepotDataList?' + 'str=' + str, 'get').then((res) => {
        var newListEnt = []
        res.data.forEach(item => {
          newListEnt.push({
            lable: item.entrPosiName,
            id: item.id,
            storageType: item.entrPosiType,
            storageCode: item.entrPosiCode
          })
        })
        this.newSelect = newListEnt
      })
    },
    // 判断库位输入库是否有值
    clearData (row) {
      if (row.ruleStorage) {
        this.newSelect = []
      }
    },
    // 点击表头库位搜索时清空数据
    clearDataTop () {
      this.newSelect = []
    },
    // 获取供应商数据列表
    supplierList () {
      request('/api/supplier/basicData/getForSelect', 'POST').then((res) => {
        if (res.code === '200') {
          res.data.forEach(item => {
            this.supplierListData.push({
              value: `${item.erpCode}-${item.name}`
            })
          })
        }
      })
    },
    // 获取供应商选中值后按钮可点击
    disChange (val) {
      this.newSupplierCode = val
      const supCode = this.newSupplierCode.lastIndexOf('-')
      this.newSupplierCode = this.newSupplierCode.substring(0, supCode)
      if (this.newSupplierCode !== '' && this.newScopeOfBusiness !== '') {
        this.postUrl = baseURL + '/api/order/PurchasingSystem/materialCodeOut?supplier=' + this.newSupplierCode + '&' + 'bs=' + this.newScopeOfBusiness
        this.keDisabled = false
        this.tableDisabled = false
      }
    },
    // 获取经营范围选中值后按钮可点击
    fwChange (val) {
      this.newScopeOfBusiness = val
      if (this.newSupplierCode !== '' && this.newScopeOfBusiness !== '') {
        this.postUrl = baseURL + '/api/order/PurchasingSystem/materialCodeOut?supplier=' + this.newSupplierCode + '&' + 'bs=' + this.newScopeOfBusiness
        this.keDisabled = false
        this.tableDisabled = false
      }
    },
    // 添加一条数据
    addBidTeam (index) {
      this.form.bidScoringList.push({
        bidScoringList: []
      })
    },
    // 删除一条数据
    removeBidTeam (index) {
      this.form.bidScoringList.splice(index, 1)
    },
    // 判断是否只能上传一个文件，超过提示报错
    handleExceed (e) {
      this.$message({
        showClose: true,
        type: 'error',
        message: '只能上传一个文件'
      })
    },
    // 验证上传文件格式
    handleChansge (file, fileList) {
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$message({
          showClose: true,
          type: 'error',
          message: '上传文件只能为excel文件，且为xlsx,xls格式'
        })
        return false
      }
    },
    // 上传失败提示
    handError () {
      this.$message({
        showClose: true,
        type: 'error',
        message: '文件上传失败'
      })
    },
    // 上传成功或错误提示
    handSuccess (response, file, fileList) {
      if (response.code === '200') {
        this.btnStarts = 2
        this.clrarList = 2 // 清空列表
        // this.form.bidScoringList = response.data
        this.form.bidScoringList = []
        response.data.forEach(item => {
          this.form.bidScoringList.push({
            materialCode: item.materialCode,
            materialDesc: item.materialDesc,
            units: item.units,
            materialNum: item.materialNum,
            storage: item.storage,
            storageCode: item.storageCode,
            storageType: item.storageType,
            inventory: item.inventory
            // orderCode: item.orderCode,
            // orderRow: item.orderRow
          })
        })
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: response.msg
        })
      }
    },
    // 删除一条数据
    DeleteTips (index, rows) {
      this.$confirm('确定要删除该条数据吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        rows.splice(index, 1)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 根据物料编号请求订单信息
    queryRulNum (row) {
      const obj = {
        ruleSupplier: this.newSupplierCode,
        materialCode: row.materialCode,
        scopeOfBusiness: this.newScopeOfBusiness
      }
      request('/api/order/PurchasingSystem/queryMemberComponent', 'GET', obj).then((res) => {
        if (res.code === '200') {
          if (res.data.code === '200') {
            this.form.bidScoringList = res.data.data
            // this.form.bidScoringList.forEach(item => {
            //   item.ruleStorage = item.storage
            // })
          } else {
            this.$message({
              showClose: true,
              type: 'error',
              message: res.data.msg
            })
          }
        }
      })
    },
    // 失去焦点查询物料编号订单信息
    itCodeBlur (row) {
      this.queryRulNum(row)
    },
    // 物料编号录入后回车查询物料编号订单信息
    itCodeEnter (row, event) {
      this.queryRulNum(row)
      if (event) {
        event.target.blur()
      }
    },

    // 失去焦点判断出库数量是否大于账面库存数量
    ckNum (row) {
      var numJust = true
      var ckNumber = Number(row.materialNum)
      var kckNumber = Number(row.inventory)
      if (ckNumber > kckNumber) {
        numJust = false
      }
      if (!numJust) {
        this.$message({
          showClose: true,
          message: '出库数量需小于或等于账面库存数量！',
          type: 'warning'
        })
        row.materialNum = ''
      }
    },
    // 确认出库
    preservation () {
      if (!this.dataBox.ruleSupplierNumber) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择供应商'
        })
        return
      }
      if (!this.dataBox.businessScope) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择经营范围'
        })
        return
      }

      var isHide = true
      this.form.bidScoringList.forEach(r => {
        if (!r.materialNum) {
          isHide = false
        }
      })
      // this.form.bidScoringList.forEach(r => {
      //   if (!r.ruleStorage) {
      //     isHide = false
      //   }
      // })
      if (isHide) {
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请完善出库物料信息'
        })
        return
      }

      if (!this.dataBox.ruleComment) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请填写备注信息'
        })
        return
      }

      // this.form.bidScoringList.forEach(item => {
      //   if (item.ruleStorage.lable) {
      //     item.storage = item.ruleStorage.lable
      //     item.storageType = item.ruleStorage.storageType
      //     item.storageCode = item.ruleStorage.storageCode
      //   }
      // })
      const obj = {
        inventoryMaterialDat: {
          ruleComment: this.dataBox.ruleComment,
          ruleOrdersType: '',
          ruleSupplierName: this.dataBox.ruleSupplierNumber,
          scopeOfBusiness: this.dataBox.businessScope
        },
        ruleMaterialInDtos: [
          ...this.form.bidScoringList
        ]
      }
      request('/api/order/PurchasingSystem/outinsertPurchasinglier', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功!'
          })
          this.$router.push({
            path: '/InventoryDelivery/OutLibrary'
          })
        }
      })
    },
    // 返回
    back: () => {
      window.history.back()
    },
    // 添加物料
    showMateriel () {
      this.dialogShow = true
    },
    // 撤销
    revoke () {
      const id = this.id
      request('/api/order/PurchasingSystem/updateOutPurchasinglier?' + 'id=' + id, 'post').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功!'
          })
          // this.getIdData()
          this.$router.push({
            path: '/InventoryDelivery/OutLibrary'
          })
        }
      })
    },
    // 撤销弹框
    deliverGoods (data) {
      this.$confirm('确认要撤销吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.revoke()
      })
    },
    // 点击添加物料弹框里的确定按钮
    action () {
      this.btnStarts = 2
      if (this.clrarList === 2) {
        this.form.bidScoringList = []
      }
      this.clrarList = 1
      var newListData = this.$refs.queryDialog.$refs.content.$refs.libraryData.selectData
      if (newListData.length > 0) {
        // this.form.bidScoringList = newListData
        this.dialogShow = false
        newListData.forEach(item => {
          this.form.bidScoringList.push({ ...item }) // 列表后追加数据
          item.ruleStorage = item.storage
          this.form.bidScoringList.forEach(a => {
            if (!a.materialCode) {
              this.form.bidScoringList.splice(item, 1)
            }
          })
        })
      } else {
        this.$message({
          showClose: true,
          message: '请选择需要入库的物料',
          type: 'warning'
        })
      }
    },
    // 下载普通物料出入库模板
    downCommonTemplate () {
      var tmUrl = '库存出库模板'
      exportForm('/api/order/PurchasingSystem/downloadMaterialExcel?title=' + tmUrl, 'post').then((res) => {
        try {
          const url = window.URL.createObjectURL(res.data)
          const eleLink = document.createElement('a')
          eleLink.href = url
          eleLink.download = '普通物料出库模板.xls'
          eleLink.target = '_blank'
          eleLink.click()
          window.URL.revokeObjectURL(url)
        } catch (error) {
          console.error('download function error!', error)
        }
      })
    },
    // 关闭弹框
    handleClose () {
      this.dialogShow = false
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
  .xj-code{
    margin-bottom: 20px;
    color: #606266;
  }
  .xj-span1{
    display: inline-block;
    width: 158px;
    text-align: right;
    padding-right: 12px;
    font-size: 14px;
  }
  .xj-span2{
    font-size: 14px;
  }
  .bidding{
    position: relative;
  }
  .bidding-ipn{
    position: absolute;
    left: 205px;
    top: -1px;
    width: 160px;
  }
  .yqgys .content{
    padding: 0 !important;
  }
  .excel-tab{
    margin-top: 10px;
    margin-bottom: 12px;
  }
  // .disabled .excel-tab{
  //   margin-bottom: 0;
  // }
  .btnlaft{
    float: left;
  }
  .mg-btnlaft{
    margin-right: 10px;
  }
  .mg-form{
    // margin-top: 20px;
    .purchClass{
      margin: 10px 0;
    }
    .action{
      margin: 20px 0 30px;
    }
    .mg-action{
      margin: 20px 0 !important;
    }
    :deep(.el-table th){
      padding: 4px 0;
      background: #ededed;
      font-size: 14px;
    }
    :deep(.el-table td){
      font-size: 14px;
    }
    :deep(.el-input__inner){
      // height: 30px !important;
      // line-height: 30px !important;
      color: #606266 !important;
    }
    // 添加label---css
    :deep(.el-form-item__label:before) {
      content: "*";
      color: #F56C6C;
      margin-right: 4px;
    }
  }
  .perch-mg-form{
    .disabled{
      .el-textarea__inner{
        background: none;
        border: none;
        font-size: 14px;
        padding: 7px 5px;
        color: #666 !important;
        font-family: 'Microsoft YaHei';
      }
      .el-input__inner{
        padding: 0;
      }
      thead{
        .el-input__inner{
          font-weight: bold;
        }
      }
      // 移除label---css
      :deep(.el-form-item__label:before) {
        content: "";
        color: #Fff;
        margin-right: 0;
      }
    }
    .el-form .col {
      padding: 0 !important;
    }
  }
</style>
